import React, { useState } from 'react';
import Menu from './Menu';
import Categories from './Categories';
import items from './data';

const sortedItems = [...items].sort((a, b) => a.title.localeCompare(b.title));
const allCategories = ['all', ...new Set(sortedItems.map(item => item.category))];
const allType = [...new Set(sortedItems.map(item => item.type))];

function App() {
  const [menuItems, setMenuItems] = useState(sortedItems);
  const [categories] = useState(allCategories.concat(allType).filter(item => item.length > 0));

  const filterItems = React.useCallback(category => {
    if (category === 'all') {
      setMenuItems(sortedItems);
      return;
    }
    const newItems = sortedItems.filter(
      (item) => item.category === category || item.type === category
    );
    setMenuItems(newItems);
  }, []);

  return (
    <main>
      <section className="menu section">
        <div className="title">
          <div>
            <img height={200} src="/images/logo_with_ig.png" alt="logo teman sepanjang masa" />
          </div>
        </div>
        <Categories categories={categories} filterItems={filterItems} />
        <Menu items={menuItems} />
      </section>
    </main>
  );
}

export default App;
